<template>
    <transition appear enter-active-class="animated fadeIn">
        <div>
            <b-loading :is-full-page="true" :active="!content"/>

            <div class="level-item">
                <h1 class="title is-1 page-title">SINGLE TRIP TRAVEL INSURANCE</h1>
            </div>
            <div class="box b-shdw-3">
                <InfoButtonOptions :policyType="'ST'" :coverType="'ST'"/>

                <!-- Injected content -->
                <div class="content" v-html="content.main"/>

                <!-- FAQs -->
                <b-collapse class="card" v-for="i in 3" :key="i" :open="false">
                    <div slot="trigger" slot-scope="props" class="card-header" role="button">
                        <p class="card-header-title" v-html="content[`faq${i}_title`]"/>
                        <a class="card-header-icon">
                            READ MORE... <b-icon :icon="props.open ? 'menu-down' : 'menu-up'"/>
                        </a>
                    </div>
                    <div class="card-content">
                        <div class="content" v-html="content[`faq${i}_content`]"/>
                    </div>
                </b-collapse>

                <!-- Bottom buttons -->
                <InfoButtonOptions :policyType="'ST'" :coverType="'ST'"/>

                <br><br>
                <info-boxes/>
                <!-- <trustpilot-banner/> -->
            </div>
        </div>
    </transition>
</template>

<script>
    import InfoBoxes from "../components/common/InfoBoxes";
    import InfoButtonOptions from "@/components/common/InfoButtonOptions";
    import { contentAPI } from "@/mixins/content-api";
    import { page } from "@/mixins/page";

    export default {
        name: "SingleTrip",
        mixins: [ contentAPI, page ],
        components: {
            InfoBoxes,
            InfoButtonOptions
        },
        methods: {
            loadTrustpilot() {
                const trustbox = document.getElementById('trustbox');
                window.Trustpilot.loadFromElement(trustbox);
            }
        },
        updated() {
            this.loadTrustpilot();
        }
    }
</script>

<style lang="scss" scoped>
    @import "src/styles/views/info-page";
</style>